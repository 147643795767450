import React from "react";

import Layout from "../../Components/Layout";
import AddParkingCharging from "../../Components/AddParkingArea";

interface Props {
  type: "PARKING" | "CHARGING" | "SOCIETY";
}

const AddParkingChargingPage = ({ type }: Props) => {
  return (
    <Layout showHeader isPrivate>
      <AddParkingCharging type={type} />
    </Layout>
  );
};

export default AddParkingChargingPage;
